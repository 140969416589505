import React, {useCallback, useEffect} from 'react';
import {Grid, Box, Typography, Tooltip, useMediaQuery} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {DateTime} from 'luxon';

import {getAlarmModeComponent} from '../sitesAlarms/AlarmModeIcon';
import CollapsibleTable from '../../../../shared/components/collapsibleTable';
import {
  getAlarmSummaryForSites,
  getDomainAlarmSchedules,
} from '../../../../api/alarms';
import {useApi} from '../../../../shared/hooks';

const columnDefinitions = [
  {
    id: 'nameAndDescription',
    label: 'Template Name',
    style: {width: '25%'},
  },
  {
    id: 'startEnd',
    label: 'Start/End',
    style: {width: '25%', textAlign: 'center'},
  },
  {
    id: 'alarmMode',
    label: 'Mode',
    style: {width: '25%', textAlign: 'center'},
    notSortable: true,
  },
  {
    id: 'sitesAssigned',
    label: 'Sites Assigned',
    style: {width: '25%', textAlign: 'center'},
    notSortable: true,
  },
];

const initialOrderOptions = {
  initialOrderBy: 'startEnd',
  initialOrder: 'asc',
};

const fetchScheduleExceptionsTemplates = async (
  page,
  rowsPerPage,
  order,
  orderBy,
) => {
  const offset = page * rowsPerPage;
  let orderSetup = {sortByStartDate: undefined, sortByName: undefined};
  switch (orderBy) {
    case 'startEnd':
      orderSetup = {
        ...orderSetup,
        sortByStartDate: order,
      };
      break;
    case 'nameAndDescription':
      orderSetup = {
        ...orderSetup,
        sortByName: order,
      };
      break;
    default:
      break;
  }
  const results = await getDomainAlarmSchedules(
    'template',
    rowsPerPage,
    offset,
    orderSetup.sortByStartDate,
    orderSetup.sortByName,
  );
  return results;
};

const formatScheduleDate = (date) =>
  DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm').toFormat('MMM/dd/yy - hh:mma');

const renderTemplateNameAndDescription = (name, description) => (
  <Box>
    <Typography variant="body2">{name}</Typography>
    <Typography variant="caption">{description}</Typography>
  </Box>
);

const renderSitesAssigned = (
  sitesAssigned,
  sitesTotalCount,
  isMediumScreen,
) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Typography
      variant="body2"
      sx={{
        fontWeight: 500,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {!sitesAssigned && (
        <Tooltip
          data-cy="info-icon-tooltip"
          title="No sites are assigned"
          placement="top"
        >
          <InfoOutlinedIcon
            sx={{
              position: 'absolute',
              right: isMediumScreen ? 40 : 60,
              color: 'error.main',
            }}
          />
        </Tooltip>
      )}
      {sitesAssigned}/{sitesTotalCount}
    </Typography>
  </Box>
);

const renderStartEndDates = (startDate, endDate) => (
  <Box sx={{textAlign: 'center'}}>
    <Typography variant="body1">{formatScheduleDate(startDate)}</Typography>
    <Typography variant="body1">{formatScheduleDate(endDate)}</Typography>
  </Box>
);

const renderAlarmMode = (mode) => (
  <Box sx={{justifyContent: 'center', display: 'flex'}}>
    {getAlarmModeComponent(mode)}
  </Box>
);

const ScheduleExceptionsTable = () => {
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [sites, sitesLoading, , getSites] = useApi(getAlarmSummaryForSites);

  useEffect(
    () => {
      const fetchAllSites = async () =>
        getSites(
          DateTime.local().minus({
            hours: 24,
          }),
          DateTime.local(),
          undefined,
          10,
          0,
          true,
          false,
          undefined,
          undefined,
          'site_name',
          'asc',
        );
      fetchAllSites();
    },
    [getSites],
  );

  const onLoadData = useCallback(
    async (page, rowsPerPage, sortingOrder, sortingColumn) => {
      if (sites) {
        const data = await fetchScheduleExceptionsTemplates(
          page,
          rowsPerPage,
          sortingOrder,
          sortingColumn,
        );

        return {
          count: data.count,
          results: data.results.map((result) => {
            return {
              ...result,
              nameAndDescription: renderTemplateNameAndDescription(
                result.name,
                result.notes,
              ),
              startEnd: renderStartEndDates(result.startDate, result.endDate),
              alarmMode: renderAlarmMode('Armed'),
              sitesAssigned: renderSitesAssigned(
                result.sitesCount,
                sites?.count,
                isMediumScreen,
              ),
            };
          }),
        };
      }
      return {};
    },
    [sites, isMediumScreen],
  );

  return (
    <Grid data-cy="schedule-exceptions-table" item xs={12}>
      {!sitesLoading && (
        <CollapsibleTable
          rowId="id"
          columns={columnDefinitions}
          sortable
          orderOptions={initialOrderOptions}
          onLoadData={onLoadData}
          paginateOptions={{initialRecords: 10, useBasicNavigation: true}}
          stickyHeader={false}
        />
      )}
    </Grid>
  );
};

export default ScheduleExceptionsTable;
