import React, {useEffect} from 'react';
import {DateTime} from 'luxon';
import VideocamOutlined from '@mui/icons-material/VideocamOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import {Alert} from '@mui/material';

import DashboardStatusWidget from '../../../../shared/components/dashboardComponent/dashboardStatusWidget';
import {useApi} from '../../../../shared/hooks';
import {getCamerasHealthSummary} from '../../../../api/hardwareHealth';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {openInOneTab} from '../../utils';

const CameraWidget = ({widgetConfig, currentUser}) => {
  const [
    camerasHealthSummary,
    camerasHealthSummaryLoading,
    isError,
    getCamerasSummary,
  ] = useApi(getCamerasHealthSummary);

  useEffect(
    () => {
      const currentTimestamp = DateTime.utc().toISO();
      const dayAgoTimestamp = DateTime.utc()
        .minus({
          hours: 24,
        })
        .toISO();
      getCamerasSummary(dayAgoTimestamp, currentTimestamp);
    },
    [getCamerasSummary],
  );
  const count = camerasHealthSummary?.offline;
  const offlineChanges = camerasHealthSummary?.offlineChanges;
  const impairedCount = camerasHealthSummary?.impaired;
  const loading = camerasHealthSummaryLoading;

  const regularContent = [
    {
      id: 'offline-cameras',
      label: count > 0 ? 'Total Offline Cameras:' : 'All cameras are online',
      value: count ? count.toString() : undefined,
    },
    // Conditionally add the impaired cameras section based on the feature flag
    ...(currentUser.settings.enableCameraTamperingInDm
      ? [
          {
            id: 'impaired-cameras',
            label: 'Total Impaired Cameras:',
            value: impairedCount ? impairedCount.toString() : undefined,
          },
        ]
      : []),
    {
      id: 'last-day-offline-cameras',
      label: count > 0 ? 'Offline cameras in the last 24 h:' : undefined,
      value: count > 0 ? offlineChanges.toString() : undefined,
    },
  ];

  const errorContent = [
    {
      id: 'error-cameras',
      label: <Alert severity="error">Error loading widget.</Alert>,
    },
  ];

  const content = isError ? errorContent : regularContent;

  return (
    <DashboardStatusWidget
      id={widgetConfig.widgetReference}
      content={content}
      title="Cameras"
      icon={VideocamOutlined}
      loading={loading}
      pathname="/hardware-health"
      target={openInOneTab(currentUser)}
      search="?cameraStatus=offline"
      buttonLabel="View Camera Details"
      buttonIcon={OpenInNewIcon}
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(CameraWidget);
