import React from 'react';
import {Link} from 'react-router-dom';
import {checkPlatformSupport} from '../../../../shared/util/general';

import DashboardSection from '../../../../shared/components/dashboardComponent/dashboardSection';

const SspHeaderWidget = ({widgetConfig}) => {
  const actions = [
    {
      id: 'link_button',
      label: 'See full SSP landing page',
      ButtonProps: {
        component: Link,
        to: '/ssp',
      },
    },
  ];

  return (
    <DashboardSection
      id={widgetConfig.widgetReference}
      title={widgetConfig.title || 'Smart Site Protection'}
      subtitle={widgetConfig.subtitle || 'Alarms from last 7 days'}
      actions={
        checkPlatformSupport({
          supportsDesktop: true,
          minMobileVersion: '3.33.0',
        })
          ? actions
          : []
      }
      {...widgetConfig.properties}
    />
  );
};
export default SspHeaderWidget;
