import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Switch, withRouter} from 'react-router-dom';
import {Divider, List} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import omit from 'lodash/omit';
import compose from 'lodash/flowRight';
import groupBy from 'lodash/groupBy';
import merge from 'lodash/merge';
import zipObject from 'lodash/zipObject';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import StoreIcon from '@mui/icons-material/Store';
import LanIcon from '@mui/icons-material/Lan';
import SpeedIcon from '@mui/icons-material/Speed';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SetTopBoxIcon from '../../../shared/images/SetTopBoxIcon';
import DatabaseIcon from '../../../shared/images/DatabaseIcon';

import PageLoader from '../../../shared/components/pageLoader';
import {applianceListType, siteType} from '../../../shared/types/common';
import withSnackbar from '../../../shared/components/snackbarSupport';
import NavLinkListItem from '../shared/NavLinkListItem';
import PageTemplate from '../../../shared/components/pageTemplate';
import CapabilityGuard from '../../../shared/components/capabilitiesGuard';
import CapabilityEnabledRoute from '../../../shared/components/capabilityEnabledRoute';
import {getApplianceStatuses} from '../../../shared/util/appliances';
import GlobalErrorPage from '../../globalErrorPage';
import {withLogger} from '../../../shared/components/logger';

import {getCameras} from '../../../api/sensors';
import {
  getSiteCloudArchiveSettings,
  getSiteCloudArchiveHealth,
} from '../../../api/cloudArchive';
import {
  getIntegrationsSite,
  getIntegrationsDomain,
  getAdaptersList,
  getAdapterCategoryListSite,
} from '../../../api/integrations';
import {getCamerasBySiteId, getResolutions} from '../../../api/cameras';
import {
  getAudioChannelsByApplianceId,
  getAppliancesForSite,
  getAppliance,
  getAppliancePreferences,
  getNetworkDetailsFromAppliance,
  getNetworkConfigFromDatabase,
} from '../../../api/appliances';
import {getBitrate, getSiteAccessUsers, getTimezones} from '../../../api/sites';
import {
  getLocalUsers,
  createLocalUser,
  deleteLocalUser,
} from '../../../api/localUsers';

import {withCurrentUser} from '../../../shared/contexts/currentUserContext';
import allowed, {
  ENVR_ADMIN,
  INTEGRATION_ADMIN,
  CLOUD_ARCHIVE_ADMIN,
  CLOUD_ARCHIVE_USER,
  ENVY_LOCAL_ACCESS,
  GRANT_TEMP_ACCESS,
  MOTION_ALARM_ADMIN,
  ACCESS_CONTROL_ADMIN,
} from '../../../shared/util/allowed';

import {flatten} from '../../../shared/util/arrays';
import {getRegisters} from '../../../api/registers';
import {getDmpConfig} from '../../../api/dmp';
import {getDeterrents} from '../../../api/deterrents';
import {getEntries, getEntriesPerCamera} from '../../../api/accessControl';

const styles = (theme) => ({
  listItemGutters: {
    paddingRight: 0,
    paddingLeft: theme.spacing(1),
  },
  menuItemWrapper: {
    display: 'flex',
  },
  menuLabel: {
    display: 'inline',
    paddingLeft: theme.spacing(1),
  },
  applianceItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const routes = {
  '/devices': {
    capabilities: [ENVR_ADMIN],
    linkProps: {
      name: 'devices-nav',
      icon: <SetTopBoxIcon />,
      primaryText: 'Devices',
      ordering: 0,
    },
  },
  '/site-performance': {
    capabilities: [ENVR_ADMIN, CLOUD_ARCHIVE_ADMIN, CLOUD_ARCHIVE_USER],
    linkProps: {
      name: 'site-performance-nav',
      primaryText: 'Site Performance',
      icon: <SpeedIcon />,
      ordering: 1,
    },
  },
  '/envysion-local': {
    capabilities: [ENVY_LOCAL_ACCESS],
    linkProps: {
      name: 'envysion-local-nav',
      icon: <LanIcon />,
      primaryText: 'Envysion Local',
      ordering: 2,
    },
  },
  '/integrations': {
    capabilities: [INTEGRATION_ADMIN],
    linkProps: {
      name: 'integration-nav',
      icon: <DatabaseIcon />,
      primaryText: 'Integrations',
      ordering: 3,
    },
  },
  '/configuration': {
    capabilities: [ENVR_ADMIN],
    featureFlag: 'enableDmpConfiguration',
    linkProps: {
      name: 'configuration-nav',
      primaryText: 'Configuration',
      icon: <StoreIcon />,
      ordering: 4,
    },
  },
  '/site-access': {
    capabilities: [GRANT_TEMP_ACCESS],
    linkProps: {
      name: 'site-access-nav',
      icon: <ManageAccountsIcon />,
      primaryText: 'Temporary Access',
      ordering: 5,
    },
  },
};

export const settingsOrderedRoutes = Object.entries(routes)
  .filter(([, {linkProps}]) => Boolean(linkProps))
  .sort((a, b) => a[1].linkProps.ordering - b[1].linkProps.ordering)
  .map(([path, value]) => ({...value, path})); // add path to returned object

const SettingsRoute = withRouter(({match, path, ...rest}) => {
  const routeKey = path.replace(match.path, '');

  return (
    <CapabilityEnabledRoute
      capabilities={routes[routeKey].capabilities}
      path={path}
      key={`settings-route-${routeKey}`}
      renderDefault={<GlobalErrorPage />}
      {...rest}
    />
  );
});

// eslint-disable-next-line react/display-name
const SettingsNav = React.memo(({currentUser, drawerCollapsed}) => {
  return (
    <Fragment>
      <List disablePadding>
        {settingsOrderedRoutes.map(
          ({path, capabilities, linkProps, featureFlag, featureFlagOff}) =>
            (featureFlag && currentUser.settings[featureFlag]) ||
            (featureFlagOff && !currentUser.settings[featureFlagOff]) ||
            (!featureFlag && !featureFlagOff) ? (
              <CapabilityGuard
                allowed={capabilities}
                key={`settings-link-${path}`}
              >
                <NavLinkListItem
                  mini={drawerCollapsed}
                  to={path}
                  {...omit(linkProps, 'ordering')}
                />
              </CapabilityGuard>
            ) : null,
        )}
      </List>
      <Divider />
      <div style={{flexGrow: 1}} />
      <Divider />
      <List disablePadding>
        <NavLinkListItem
          mini={drawerCollapsed}
          to="/sites"
          relative={false}
          primaryText="Back to Site List"
          icon={<ArrowBackOutlinedIcon />}
        />
      </List>
    </Fragment>
  );
});

const Settings = ({
  match,
  site,
  appliances,
  snackbar,
  currentUser,
  reload,
  classes,
  siteId,
  logger,
}) => {
  const handleCreateLocalUser = async (newUser) => {
    try {
      const response = await createLocalUser(site.id, newUser);
      if (response.status && response.status.type === 'PARTIAL_SUCCESS') {
        snackbar.warning(`${response.status.message}`);
      } else {
        snackbar.success(`Created ${newUser.username} successfully`);
      }
    } catch (e) {
      snackbar.error(`Failed to create ${newUser.username}`);
      throw e;
    }
  };

  const handleDeleteLocalUser = async (localUser) => {
    if (localUser) {
      try {
        const response = await deleteLocalUser(
          localUser.siteUuid,
          localUser.id,
        );
        if (response.status && response.status.type === 'PARTIAL_SUCCESS') {
          snackbar.warning(`${response.status.message}`);
        } else {
          snackbar.success(`Deleted ${localUser.username} successfully`);
        }
      } catch (e) {
        snackbar.error(`Failed to delete ${localUser.username}`);
      }
    }
  };

  const getProfile = (cam) => {
    if (!cam.enabled) {
      return 'disabled';
    }
    if (cam.isUnmanaged) {
      return 'record_only';
    }
    return 'managed';
  };

  const isProbeable = (camera) => camera.isIp && camera.isOnline;

  return (
    <PageTemplate
      withDrawer
      drawerId="sites-nav"
      drawerContent={(drawerCollapsed) => (
        <SettingsNav
          classes={classes}
          currentUser={currentUser}
          drawerCollapsed={drawerCollapsed}
        />
      )}
      title={site.name}
      category="Sites"
      PageHeaderProps={{
        title: `${site.name} - Settings`,
        backLink: `/sites/${site.id}`,
      }}
    >
      {(collapseDrawer, expandDrawer, setActions) => {
        const wrapperProps = {
          collapseDrawer,
          expandDrawer,
          setActions,
        };
        return (
          <Switch>
            <SettingsRoute
              path={`${match.path}/devices`}
              exact
              render={(renderProps) => (
                <PageLoader
                  page={() => import('./devices')}
                  resources={{
                    camerasAppliancesAndAudioChannels: async () => {
                      const siteCameras = await getCamerasBySiteId(site.id);
                      const camerasByAppliance = groupBy(
                        siteCameras,
                        'applianceId',
                      );

                      const getAppliances = async () => {
                        const siteAppliances = await getAppliancesForSite(
                          siteId,
                        );

                        return Promise.all(
                          siteAppliances.map(async (a) => ({
                            ...(await getAppliance(a.id)),

                            cameraDiscovery: a.cameraDiscovery,
                          })),
                        );
                      };
                      const fetchedAppliances = await getAppliances();
                      const activeAppliances = fetchedAppliances.filter(
                        (appliance) => appliance.active === true,
                      );
                      const appliancesById = fetchedAppliances.reduce(
                        (acc, appliance) => {
                          acc[appliance.id] = appliance;
                          return acc;
                        },
                        {},
                      );

                      const getAllAppliancePreferences = await Promise.all(
                        fetchedAppliances.map(async (a) => {
                          const preferences = await getAppliancePreferences(
                            a.id,
                          );
                          return preferences.reduce((acc, p) => {
                            if (p.name === 'maxRetentionHours') {
                              // eslint-disable-next-line no-param-reassign
                              a.maxRetentionHours = p.value;
                            }
                            if (p.visible) {
                              acc.push(p);
                            }
                            return acc;
                          }, []);
                        }),
                      );

                      const appliancePreferencesByApplianceId = zipObject(
                        fetchedAppliances.map((appliance) => appliance.id),
                        getAllAppliancePreferences,
                      );

                      const getNetworkConfigsAndDetails = async () => {
                        try {
                          const applianceNetworkConfigsByApplianceId = {};
                          const applianceNetworkDetailsByApplianceId = {};
                          await Promise.all(
                            fetchedAppliances.map(async (appliance) => {
                              const [config, details] = await Promise.all([
                                getNetworkConfigFromDatabase(appliance.id),
                                getNetworkDetailsFromAppliance(
                                  appliance.id,
                                ).catch((error) => {
                                  logger.error(
                                    'Failed to fetch network details',
                                    {},
                                    error,
                                  );
                                  return {};
                                }),
                              ]);

                              const initNetworkConfig = (network) => {
                                if (!config[network] && details[network]) {
                                  config[network] = {
                                    ipv4Address: details[network]?.[0].address,
                                    ipv4Netmask: details[network]?.[0].netmask,
                                  };
                                }
                              };

                              initNetworkConfig('eth0:1');
                              initNetworkConfig('eth1');

                              applianceNetworkConfigsByApplianceId[
                                appliance.id
                              ] = config;

                              applianceNetworkDetailsByApplianceId[
                                appliance.id
                              ] = {
                                'eth0:1': {
                                  present: details['eth0:1']?.[0]?.present,
                                  connected: details['eth0:1']?.[0]?.connected,
                                },
                                eth1: {
                                  present: details.eth1?.[0]?.present,
                                  connected: details.eth1?.[0]?.connected,
                                },
                              };
                              return config;
                            }),
                          );
                          return {
                            applianceNetworkConfigsByApplianceId,
                            applianceNetworkDetailsByApplianceId,
                          };
                        } catch (error) {
                          logger.error(
                            'Failed to fetch network configs',
                            {},
                            error,
                          );
                          throw new Error('Failed to fetch network configs');
                        }
                      };

                      const {
                        applianceNetworkConfigsByApplianceId,
                        applianceNetworkDetailsByApplianceId,
                      } = await getNetworkConfigsAndDetails();

                      const primaryNetworkConfigsByApplianceId = fetchedAppliances.reduce(
                        (acc, appliance) => {
                          acc[appliance.id] =
                            appliancesById[appliance.id]?.network?.eth0;
                          return acc;
                        },
                        {},
                      );

                      const audioChannels = await Promise.all(
                        fetchedAppliances.map(async ({id}) =>
                          getAudioChannelsByApplianceId(id),
                        ),
                      );
                      const audioChannelsByApplianceIdAndAudioChannel = audioChannels
                        .flat()
                        .reduce((acc, audioChannel) => {
                          if (!acc[audioChannel.applianceId]) {
                            acc[audioChannel.applianceId] = {};
                          }
                          acc[audioChannel.applianceId][
                            audioChannel.channel
                          ] = audioChannel;
                          return acc;
                        }, {});

                      const probeResponsesByAppliance = await Promise.all(
                        Object.entries(camerasByAppliance).map(
                          async ([, cameras]) => {
                            const probeableCameras = cameras.filter((camera) =>
                              isProbeable(camera),
                            );

                            const probedCameras = probeableCameras.map(
                              (camera) => ({
                                ip_address: camera.ipAddress,
                                manageable: true,
                              }),
                            );
                            return probedCameras.reduce(
                              (acc, probe, idx) => ({
                                ...acc,
                                [probeableCameras[idx].id]: probe,
                              }),
                              {},
                            );
                          },
                        ),
                      );

                      const probesByCameraId = merge(
                        ...probeResponsesByAppliance,
                      );
                      return {
                        cameras: siteCameras.map((cam) => {
                          const cameraTiedToAudioChannel =
                            audioChannelsByApplianceIdAndAudioChannel[
                              cam.applianceId
                            ] &&
                            audioChannelsByApplianceIdAndAudioChannel[
                              cam.applianceId
                            ][cam.audioRecordChannel];
                          const {dvrMakeName} = appliancesById[cam.applianceId];
                          const getAudioGain = () => {
                            if (dvrMakeName === 'Envysion') {
                              return cameraTiedToAudioChannel
                                ? audioChannelsByApplianceIdAndAudioChannel[
                                    cam.applianceId
                                  ][cam.audioRecordChannel].gain
                                : null;
                            }
                            return (
                              audioChannelsByApplianceIdAndAudioChannel[
                                cam.applianceId
                              ]?.[cam.channel]?.gain ?? null
                            );
                          };

                          return {
                            ...cam,
                            audioRecordChannelId: cameraTiedToAudioChannel
                              ? audioChannelsByApplianceIdAndAudioChannel[
                                  cam.applianceId
                                ][cam.audioRecordChannel].id
                              : null,
                            recordAudio: cameraTiedToAudioChannel
                              ? !!audioChannelsByApplianceIdAndAudioChannel[
                                  cam.applianceId
                                ][cam.audioRecordChannel].recordEnabled
                              : false,
                            audioGain: getAudioGain(),
                            img: `/api/v3/sensor_views/${cam.id}/thumbnail`,
                            profile: getProfile(cam),
                            isProbeable: isProbeable(cam),
                            probe: probesByCameraId[cam.id] || {},
                          };
                        }),
                        audioChannels: audioChannels
                          .flat()
                          .map((audioChannel) => ({
                            ...audioChannel,
                            recordEnabled: !!audioChannel.recordEnabled,
                          })),
                        appliances: fetchedAppliances,
                        activeAppliances,
                        applianceStatuses: await getApplianceStatuses(
                          activeAppliances,
                        ),
                        appliancePreferences: appliancePreferencesByApplianceId,
                        primaryNetworkConfigs: primaryNetworkConfigsByApplianceId,
                        applianceNetworkConfigs: applianceNetworkConfigsByApplianceId,
                        applianceNetworkDetails: applianceNetworkDetailsByApplianceId,
                      };
                    },
                    registers: getRegisters,
                    resolutions: getResolutions,
                    timezones: getTimezones,
                    cloudArchiveSettings: () =>
                      getSiteCloudArchiveSettings(siteId),
                    cloudArchiveHealth: async () => {
                      let cloudArchiveHealthStatus = {};
                      try {
                        cloudArchiveHealthStatus = await getSiteCloudArchiveHealth(
                          siteId,
                        );
                      } catch (error) {
                        logger.error(
                          'Failed to fetch cloud archive health',
                          {},
                          error,
                        );
                      }
                      return cloudArchiveHealthStatus;
                    },
                    entries: () =>
                      allowed(currentUser, [ACCESS_CONTROL_ADMIN])
                        ? getEntries(siteId)
                        : Promise.resolve([]),
                    entriesPerCamera: () =>
                      allowed(currentUser, [ACCESS_CONTROL_ADMIN])
                        ? getEntriesPerCamera(siteId)
                        : Promise.resolve([]),
                    dmpConfig: () =>
                      allowed(currentUser, [MOTION_ALARM_ADMIN])
                        ? getDmpConfig(siteId)
                        : Promise.resolve(null),
                    deterrents: () =>
                      allowed(currentUser, [ENVR_ADMIN])
                        ? getDeterrents(siteId).then((data) => data.results)
                        : Promise.resolve([]),
                  }}
                  {...wrapperProps}
                  reloadSettings={reload}
                  propsToIgnoreUpdate={['location', 'snackbar']}
                  snackbar={snackbar}
                  siteId={site.id}
                  enableEntryConfiguration={allowed(currentUser, [
                    ACCESS_CONTROL_ADMIN,
                  ])}
                  loadedToPageProps={({
                    camerasAppliancesAndAudioChannels,
                    registers,
                    resolutions,
                    timezones,
                    entries,
                    entriesPerCamera,
                    dmpConfig,
                    cloudArchiveSettings,
                    cloudArchiveHealth,
                    deterrents,
                  }) => {
                    return {
                      cameras: camerasAppliancesAndAudioChannels.cameras,
                      audioChannels:
                        camerasAppliancesAndAudioChannels.audioChannels,
                      registers,
                      resolutions,
                      timezones,
                      appliances: camerasAppliancesAndAudioChannels.appliances,
                      activeAppliances:
                        camerasAppliancesAndAudioChannels.activeAppliances,
                      applianceStatuses:
                        camerasAppliancesAndAudioChannels.applianceStatuses,
                      appliancePreferences:
                        camerasAppliancesAndAudioChannels.appliancePreferences,
                      primaryNetworkConfigs:
                        camerasAppliancesAndAudioChannels.primaryNetworkConfigs,
                      applianceNetworkConfigs:
                        camerasAppliancesAndAudioChannels.applianceNetworkConfigs,
                      entries,
                      entriesPerCamera,
                      dmpConfig,
                      applianceNetworkDetails:
                        camerasAppliancesAndAudioChannels.applianceNetworkDetails,
                      cloudArchiveSettings,
                      cloudArchiveHealth,
                      deterrents,
                    };
                  }}
                  {...renderProps}
                />
              )}
            />
            <SettingsRoute
              path={`${match.path}/envysion-local`}
              exact
              render={() => (
                <PageLoader
                  key="envysionLocal"
                  page={() => import('./envysionLocal')}
                  onCreate={handleCreateLocalUser}
                  onDelete={handleDeleteLocalUser}
                  propsToIgnoreUpdate={['snackbar', 'onCreate', 'onDelete']}
                  resources={{
                    localUsers: async () => getLocalUsers(site.id),
                    site: () => Promise.resolve(site),
                  }}
                  {...wrapperProps}
                />
              )}
            />
            <SettingsRoute
              path={`${match.path}/configuration`}
              exact
              render={() => (
                <PageLoader
                  key="overview"
                  page={() => import('./Configuration')}
                  siteId={site.id}
                />
              )}
            />
            <SettingsRoute
              path={`${match.path}/site-performance`}
              exact
              render={() => (
                <PageLoader
                  key="site-performance"
                  page={() => import('./sitePerformance')}
                  resources={{
                    site: () => Promise.resolve(site),
                    appliances: () => Promise.resolve(appliances),
                    match: () => Promise.resolve(match),
                    averageBitrates: () => getBitrate(site.id),
                    camerasAndSettings: async () => {
                      const allCameras = await getCameras(site.id);
                      return {allCameras};
                    },
                  }}
                />
              )}
            />
            <SettingsRoute
              path={`${match.path}/integrations`}
              render={() => (
                <CapabilityGuard
                  allowed={[INTEGRATION_ADMIN]}
                  renderDefault={() => <GlobalErrorPage error="NoAuth" />}
                >
                  <PageLoader
                    key="site-integration"
                    page={() => import('./integrations')}
                    siteId={site.id}
                    resources={{
                      appliances: () => Promise.resolve(appliances),
                      integrationList: () => getIntegrationsSite(site.id),
                      domainIntegrationList: () =>
                        getIntegrationsDomain(currentUser.domainId),
                      adapterList: () => getAdaptersList(),
                      categoryList: () => getAdapterCategoryListSite(),
                    }}
                    setActions={setActions}
                    propsToIgnoreUpdate={['snackbar']}
                  />
                </CapabilityGuard>
              )}
            />
            <SettingsRoute
              path={`${match.path}/site-access`}
              exact
              render={() => (
                <PageLoader
                  key="site-access"
                  page={() => import('./siteAccess')}
                  resources={{users: () => getSiteAccessUsers(site.id)}}
                  setActions={setActions}
                />
              )}
            />
            <SettingsRoute
              path={`${match.path}/activity-log`}
              exact
              render={() => (
                <PageLoader
                  key="overview"
                  page={() => import('../details/hardwareEvents')}
                  siteId={site.id}
                />
              )}
            />
          </Switch>
        );
      }}
    </PageTemplate>
  );
};

Settings.propTypes = {
  site: PropTypes.shape(siteType).isRequired,
  appliances: PropTypes.arrayOf(PropTypes.shape(applianceListType)),
};

Settings.defaultProps = {
  appliances: [],
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles),
  withCurrentUser,
  withLogger,
)(Settings);

export const settingsCapabilities = Object.values(routes).some(
  (r) => r.capabilities.length === 0,
)
  ? []
  : flatten(Object.values(routes).map((r) => r.capabilities));
