import React, {useState} from 'react';
import classNames from 'classnames';
import {Paper, Tab, Tabs, Badge} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import TabPanel from './TabPanel';

const useStyles = makeStyles((theme) => ({
  tabBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  badgeWrapper: {
    padding: [[0, theme.spacing(2)]],
    [theme.breakpoints.down('sm')]: {
      padding: [[0, theme.spacing(0.5)]],
    },
  },
  badge: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.common.white,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
}));

const TabBar = (props) => {
  const {
    tabs,
    tabProps,
    PaperProps,
    onChange,
    selectedTab: chosenTab,
    tabAction,
    ...rest
  } = props;

  const [selectedTab, setSelectedTab] = useState(parseInt(chosenTab, 10) || 0);
  const classes = useStyles(props);
  const a11yProps = (label, panel = false) => {
    return {
      [panel ? 'aria-labelledby' : 'id']: `tab-${kebabCase(label)}`,
      [panel ? 'id' : 'aria-controls']: `tabpanel-${kebabCase(label)}`,
    };
  };

  return (
    <div {...rest}>
      <Paper square {...PaperProps} className={classes.tabBar}>
        <Tabs
          variant="scrollable"
          value={selectedTab}
          indicatorColor="primary"
          onChange={(evt, newTab) => {
            onChange(newTab);
            setSelectedTab(newTab);
          }}
        >
          {tabs.map((t) => (
            <Tab
              label={
                <Badge
                  name={`${t.key}-changed-count`}
                  className={classes.badgeWrapper}
                  classes={{
                    badge: classNames(classes.badge, {
                      [classes.error]: t.errors,
                    }),
                  }}
                  badgeContent={t.changes || undefined}
                  overlap="rectangular"
                >
                  {t.label}
                </Badge>
              }
              key={t.key || t.label}
              {...a11yProps(t.key || t.label)}
            />
          ))}
        </Tabs>
        {!!tabAction && tabAction}
      </Paper>
      {tabs.map((t, idx) => {
        return (
          <TabPanel
            value={selectedTab}
            key={kebabCase(t.key || t.label)}
            index={idx}
            {...a11yProps(t.key || t.label, true)}
          >
            {React.createElement(t.component, {
              ...tabProps,
              ...(t.props || {}),
            })}
          </TabPanel>
        );
      })}
    </div>
  );
};

TabBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabAction: PropTypes.node,
  /* eslint-disable react/forbid-prop-types */
  PaperProps: PropTypes.object,
  tabs: PropTypes.array.isRequired,
  tabProps: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
};

TabBar.defaultProps = {
  selectedTab: 0,
  PaperProps: {},
  tabProps: {},
  tabAction: null,
};

export default TabBar;
