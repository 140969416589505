import React from 'react';

import {Grid, Paper, Tab, Tabs, useMediaQuery} from '@mui/material';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';

const AlarmConfigurationPageTabs = ({onTabChange, activeTab}) => {
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  return (
    <Grid item xs={12} lg={activeTab ? 9 : 12}>
      <Paper>
        <Tabs
          data-cy="alarm-configuration-page-tabs"
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => onTabChange(_, v)}
          variant={isMediumScreen ? 'fullWidth' : 'standard'}
        >
          <Tab
            label="Configuration"
            sx={{textAlign: isMediumScreen ? 'center' : 'flex-start'}}
          />

          <Tab label="Schedule Exceptions" sx={{textAlign: 'center'}} />
        </Tabs>
      </Paper>
    </Grid>
  );
};

export default withCurrentUser(AlarmConfigurationPageTabs);
