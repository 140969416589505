import React, {useRef, useState} from 'react';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  styled,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';

import defaultThumbnail from '../../images/defaultThumbnail.png';
import allowed, {ENVR_ADMIN} from '../../util/allowed';
import withSnackbar from '../snackbarSupport';
import {getApplianceUrl} from '../../../api/appliances';
import {updateTamperImgRef} from '../../../api/cameras';

const StatusIcon = styled(CircleIcon)(({theme}) => ({
  margin: theme.spacing(0, 1, 0, 1),
  fontSize: '0.9rem',
  alignSelf: 'center',
}));

const TamperPanel = (props) => {
  const {children, value, index, classes, ...other} = props;
  return (
    <Box hidden={value !== index} {...other} id="1">
      {value === index && <Box id="tamper-content">{children}</Box>}
    </Box>
  );
};

const Thumbnail = ({title, imgSrc, loading, imgFallback, onLoad}) => {
  return (
    <Box sx={{m: 1}} className="thumbnail">
      <Box
        sx={{
          width: 320,
          border: '1px solid gainsboro',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <Typography sx={{p: 1}}>{title}</Typography>
      </Box>
      <Box sx={{width: 320}} className="thumbnail-img">
        {loading ? (
          <CircularProgress size={70} sx={{mt: '15%', ml: '40%'}} />
        ) : (
          <img
            src={imgSrc}
            alt=""
            width="320"
            height="240"
            onLoad={() => {
              if (imgSrc !== defaultThumbnail) {
                onLoad(true);
              }
            }}
            onError={imgFallback}
          />
        )}
      </Box>
    </Box>
  );
};

const ImageRefPicker = ({
  refPickerTime,
  earliestDate,
  loading,
  preview,
  onChange,
}) => {
  return (
    <Box className="tamper-ref-picker" sx={{width: 320, ml: 1}}>
      <DateTimePicker
        sx={{p: 0.5}}
        label="Date Input"
        value={refPickerTime}
        onChange={onChange}
        timeSteps={{hours: 1, minutes: 1, seconds: 1}}
        minDate={earliestDate}
        disableFuture
        slotProps={{
          actionBar: {actions: ['clear', 'cancel', 'accept']},
        }}
      />
      <Box sx={{minHeight: 100}}>
        {loading ? (
          <Typography>Updating image...</Typography>
        ) : (
          <React.Fragment>
            <Typography sx={{mb: 1}}>
              Selecting a time will preview an image, which can then be set as
              the reference image.
            </Typography>
            {preview && (
              <Typography>
                Click Update to set the new reference image.
              </Typography>
            )}

            {!preview && (
              <Typography>
                No thumbnail available during this time period.
              </Typography>
            )}
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
};

const CameraTamperModal = ({onClose, cameraData, currentUser, snackbar}) => {
  const {
    applianceId,
    hardwareId,
    hardwareName,
    hardwareStatus,
    displayMessage,
    earliestData,
    applianceSensorNum,
    timeZone,
  } = cameraData;
  const impaired = hardwareStatus === 'Impaired';

  const earliestDate = DateTime.fromISO(earliestData);
  const canUpdateRefImage = allowed(currentUser, [ENVR_ADMIN]);

  const defaultTime = DateTime.local()
    .setZone(timeZone)
    .set({second: 0, millisecond: 0});
  const [refPickerTime, setRefPickerTime] = useState(defaultTime);
  const [preview, setPreview] = useState(false);
  const [activePanel, setActivePanel] = useState('main');
  const [loading, setLoading] = useState(false);
  const previewNonce = useRef(0);

  const getThumbnailUrl = (timestamp) => {
    const imageRes = '320x-1';
    const time = timestamp ? `&start=${encodeURIComponent(timestamp)}` : '';

    const thumbnailUrl = `${getApplianceUrl(
      applianceId,
    )}/aapi/dvr/thumbnail?channel=${applianceSensorNum}${time}&resolution=${imageRes}&nonce=${
      previewNonce.current
    }`;

    previewNonce.current += 1;

    return thumbnailUrl;
  };

  const [mainImgSrc, setMainImgSrc] = useState(
    impaired ? `/api/v3/cameras/${hardwareId}/tamper_image` : getThumbnailUrl(),
  );
  const dayImgDefault = `/api/v3/cameras/${hardwareId}/reference_images/day`;
  const nightImgDefault = `/api/v3/cameras/${hardwareId}/reference_images/night`;
  const [dayImgSrc, setDayImgSrc] = useState(dayImgDefault);
  const [nightImgSrc, setNightImgSrc] = useState(nightImgDefault);

  const handleTabChange = (newValue) => {
    if (newValue === activePanel) {
      setActivePanel('main');
    } else {
      setActivePanel(newValue);
    }
  };

  const handleImgRefUpdate = async () => {
    const body = {start: refPickerTime.toISO()};
    setPreview(false);
    try {
      await updateTamperImgRef(activePanel, hardwareId, body);
      if (activePanel === 'day') {
        setDayImgSrc(dayImgDefault);
      } else {
        setNightImgSrc(nightImgDefault);
      }
      snackbar.success(
        'Successfully updated reference image. Please wait a moment for the image to update.',
      );
    } catch (e) {
      snackbar.error(
        `Failed to update reference image. If this issue persists please contact support for assistance.`,
      );
    }
    setLoading(false);
  };

  const handleImgFallback = (thumbnailRef) => {
    if (thumbnailRef === 'preview') {
      setPreview(false);
      setMainImgSrc(defaultThumbnail);
    }
    if (thumbnailRef === 'day') {
      setDayImgSrc(defaultThumbnail);
    }
    if (thumbnailRef === 'night') {
      setNightImgSrc(defaultThumbnail);
    }
  };

  const DialogHeader = ({title}) => {
    return (
      <Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h5">{title}</Typography>
          <IconButton sx={{p: 0, mb: 1}} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          id="dialog-header"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 1,
            my: 1,
            backgroundColor: 'primary.lightest',
          }}
        >
          <Typography variant="body">Camera: {hardwareName}</Typography>
          <Typography variant="body" sx={{display: 'flex'}}>
            Status: <StatusIcon color={impaired ? 'warning' : 'success'} />
            {hardwareStatus}
          </Typography>
          <Typography variant="body">Description: {displayMessage}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box id="tamper-dialog">
      <DialogContent sx={{pb: 0}}>
        <TamperPanel value={activePanel} index="main">
          <DialogHeader title="Image Quality Monitoring" />
          <Box sx={{display: 'flex'}}>
            <Thumbnail
              title="Day Reference Image"
              imgSrc={dayImgSrc}
              imgFallback={() => handleImgFallback('day')}
            />
            <Thumbnail
              title="Night Reference Image"
              imgSrc={nightImgSrc}
              imgFallback={() => handleImgFallback('night')}
            />
          </Box>
          <Box sx={{ml: '25%'}}>
            <Thumbnail
              id="thumbnail"
              title={impaired ? 'Detection Image' : 'Live Thumbnail Preview'}
              imgSrc={mainImgSrc}
              imgFallback={() => handleImgFallback('preview')}
            />
          </Box>
        </TamperPanel>
        <TamperPanel value={activePanel} index="day">
          <DialogHeader title="Update Day Reference Image" />
          <Box sx={{display: 'flex'}}>
            <Thumbnail
              title="Day Preview"
              imgSrc={mainImgSrc}
              imgFallback={() => handleImgFallback('preview')}
              onLoad={setPreview}
            />
            <Thumbnail
              title="Day Reference Image"
              loading={loading}
              imgSrc={dayImgSrc}
              imgFallback={() => handleImgFallback('day')}
            />
          </Box>
          <ImageRefPicker
            refPickerTime={refPickerTime}
            setPreview={setPreview}
            setRefPickerTime={setRefPickerTime}
            earliestDate={earliestDate}
            loading={loading}
            preview={preview}
            onChange={(newTime) => {
              setMainImgSrc(getThumbnailUrl(newTime));
              setRefPickerTime(newTime);
              setPreview(false);
            }}
          />
        </TamperPanel>
        <TamperPanel value={activePanel} index="night">
          <DialogHeader title="Update Night Reference Image" />
          <Box sx={{display: 'flex'}}>
            <Thumbnail
              title="Night Preview"
              imgSrc={mainImgSrc}
              imgFallback={() => handleImgFallback('preview')}
              onLoad={setPreview}
            />
            <Thumbnail
              title="Night Reference Image"
              loading={loading}
              imgSrc={nightImgSrc}
              imgFallback={() => handleImgFallback('night')}
            />
          </Box>
          <ImageRefPicker
            refPickerTime={refPickerTime}
            setPreview={setPreview}
            setRefPickerTime={setRefPickerTime}
            earliestDate={earliestDate}
            loading={loading}
            preview={preview}
            onChange={(newTime) => {
              setMainImgSrc(getThumbnailUrl(newTime));
              setRefPickerTime(newTime);
              setPreview(false);
            }}
          />
        </TamperPanel>
      </DialogContent>
      <DialogActions id="tamper-actions" sx={{mx: 1, pt: 0}}>
        {canUpdateRefImage && (
          <Box>
            {activePanel === 'main' ? (
              <React.Fragment>
                <Button
                  id="update-day-ref"
                  variant="text"
                  onClick={() => handleTabChange('day')}
                  sx={{mr: 2}}
                >
                  Update Day Reference Image
                </Button>
                <Button
                  id="update-night-ref"
                  variant="text"
                  onClick={() => handleTabChange('night')}
                  sx={{mr: 2}}
                >
                  Update Night Reference Image
                </Button>
              </React.Fragment>
            ) : (
              <Button
                id="back"
                variant="text"
                onClick={() => handleTabChange('main')}
                sx={{mr: 2}}
              >
                Back
              </Button>
            )}
            {activePanel !== 'main' && (
              <Button
                id="update-allowed"
                onClick={handleImgRefUpdate}
                sx={{mr: 2}}
                disabled={!preview}
              >
                Update
              </Button>
            )}
          </Box>
        )}
        <Button id="tamper-close" variant="text" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Box>
  );
};

TamperPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string,
};

TamperPanel.defaultProps = {
  children: undefined,
  value: undefined,
  index: undefined,
};

Thumbnail.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  loading: PropTypes.bool,
  imgFallback: PropTypes.func,
  onLoad: PropTypes.func,
};

Thumbnail.defaultProps = {
  title: undefined,
  imgSrc: undefined,
  loading: undefined,
  imgFallback: undefined,
  onLoad: () => {},
};

ImageRefPicker.propTypes = {
  refPickerTime: PropTypes.shape({}).isRequired,
  setPreview: PropTypes.func.isRequired,
  setRefPickerTime: PropTypes.func.isRequired,
  earliestDate: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  preview: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

ImageRefPicker.defaultProps = {
  onChange: () => {},
};

CameraTamperModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  cameraData: PropTypes.shape({
    applianceId: PropTypes.string,
    hardwareId: PropTypes.string,
    hardwareName: PropTypes.string,
    hardwareStatus: PropTypes.string,
    displayMessage: PropTypes.string,
    earliestData: PropTypes.string,
    applianceSensorNum: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    timeZone: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string,
  title: PropTypes.string,
};

CameraTamperModal.defaultProps = {
  children: undefined,
  value: undefined,
  index: undefined,
  title: undefined,
};

export default withSnackbar(CameraTamperModal);
